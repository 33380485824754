/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";


// :root {
//   --ion-font-family: 'PT Serif', serif;
// }


.modal-fullscreen {
    --width:100% !important;
    --height:100% !important;
}

.custom-popover {
    // --width: 90%; /* Defina a largura desejada */
    --max-height: 100%;
    --max-width: 100%;
    --min-width: 90%;
    // --offset-y: -180px;
    --offset-y: -133px;
    --offset-x: 15px;
  }
  
  
  .custom-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .custom-button span {
    text-align: center;
    flex-grow: 1;
  }

  .header-toolbar {
    --background: #ffffff;
    --border-width: 0;
    box-shadow: none;
    color: black;
  }

  .header-toolbar {
    --background: var(--ion-color-header);
    --border-width: 0;
    box-shadow: none;
    border-bottom: 0;
    .header-menu-button {
      color: var(--ion-text-header);
    }
    .header-title {
      color: var(--ion-text-header);
    }
    .header-ion-button {
      font-size: 20px;
      color: var(--ion-icon-header);
      padding-right: 10px;
    }
  }

  .no-scroll {
    overflow: hidden;
  }

  .transfer-booking-popover {
    --width: 80%;
    --height: 35%;
    --max-width: 80%;
    --max-height: 35%;
    margin-left: 5%;
  }
  
  @media (min-width: 768px) { /* Telas maiores que 768px */
    .content-container {
      max-width: 700px; /* Ou qualquer largura máxima desejada */
      margin: 0 auto; /* Centraliza o container */
      padding: 0 30px; /* Aumenta o padding nas laterais */
    }
  }
  

  .icon-header-end {
    font-size: 25px;
    padding-right: 10px;
    color: white;
  }

  .no-card {
    background: none;
    --background: none;
    box-shadow: none; 
  }